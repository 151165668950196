import {useEffect, useState} from "react";
import {baseUrl} from "../../../config";
import {useNavigate, useParams} from "react-router-dom";
import {DocumentDetailed} from "../../../models/documents/Documents";
import DocumentDonationCardDetails from "./donation/DocumentDonationCardDetails";
import GoBackButton from "../../../components/GoBackButton";
import DocumentCardHistory from "./history/DocumentCardHistory";
import DocumentLoanCardDetails from "./loan/DocumentLoanCardDetails";
import {displayDocTypeName} from "../../../services/documents/DocumentUtils";
import {DocumentTypes} from "../../../models/documents/DocumentViews";


function DocumentCard() {
    const {docNumber} = useParams();
    const navigate = useNavigate();
    const [item, setItem] = useState<DocumentDetailed>();

    useEffect(() => {
        fetch(baseUrl + `/document-service/get-document?number=${docNumber}`)
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Network response was not ok");
                }
                return res.json();
            })
            .then((response) => {
                setItem(response.data);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [docNumber]);

    const goToDocumentList = () => navigate(`/documents`);

    return (
        <>
            <GoBackButton
                btnLabel={`${displayDocTypeName(item!)} ${item?.number}`}
                onClick={goToDocumentList}/>
            <div className="document-card">
                <div className="document-card_data">
                    <DocumentCardHistory doc={item!}/>
                </div>
                <div className="document-card_details">
                    {(() => {
                        switch (item?.documentType?.name) {
                            case DocumentTypes.DONATION: {
                                return <DocumentDonationCardDetails></DocumentDonationCardDetails>
                            }
                            case DocumentTypes.LOAN: {
                                return <DocumentLoanCardDetails doc={item}></DocumentLoanCardDetails>
                            }
                            default: {
                                return <div>Unknown document type</div>
                            }
                        }
                    })()}
                </div>
            </div>
        </>
    );
}

export default DocumentCard;

