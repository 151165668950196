import {docTypeAndStatusMap, DocumentDetailed} from "../../../../models/documents/Documents";
import DocumentCardHistoryTabCompleted from "./DocumentCardHistoryTabCompleted";
import DocumentCardHistoryTabActive from "./DocumentCardHistoryTabActive";


function DocumentCardHistory(
    {
        document,
    }:
    Readonly<{
        document: DocumentDetailed,
    }>
) {


    const completedTabs = document?.documentStatusTransferHistory?.map((transferInfo, index) => {
        const title = docTypeAndStatusMap.get(document.documentType.name)?.title[index] ?? 'Operation is completed'
        const statusName = docTypeAndStatusMap.get(document.documentType.name)?.statusName[index] ?? 'Completed'
        return <DocumentCardHistoryTabCompleted key={'doc_history_complete_tab_' + index}
                                                tabTitle={title}
                                                tabStatusName={statusName}
                                                items={document.bookedItems ?? []}
                                                currentStatusTransfer={transferInfo}
                                                isAccordionOpen={true}/>
    })

    const activeTab = () => {
        if (!isDocumentFullyCompleted()) {
            switch (document?.documentType?.name) {
                case 'Gift': {
                    return <DocumentCardHistoryTabActive doc={document}/>
                }
            }
        }
    }

    // todo done with Loan doc type
    const planedTabs = <></>


    const isDocumentFullyCompleted = () => {
        switch (document?.documentType?.name) {
            case 'Gift': {
                // donation has only 2 steps
                return document?.documentStatusTransferHistory?.length === 2
            }
        }
    }

    return (
        <>
            {completedTabs}
            {activeTab()}
            {planedTabs}
        </>
    );
}

export default DocumentCardHistory;

