import {Equipment, EquipmentGroup} from "../equipment/Equpment";
import {UserDto} from "../users/UserDto";

export interface DocumentItem {
    number: string;
    documentType: DocumentType;
    consumer: Consumer;
    supplier: Supplier;
    documentStatusTransfer: DocumentStatusTransfer;
}

export interface DocumentType {
    name: string;
}

export interface DocumentStatusTransfer {
    toStatus: string;
    operationDate: Date;
    initiator: UserDto;
}

export interface Consumer {
    name: string;
    surname: string;
}

export interface Supplier {
    name: string;
    surname: string;
}

export interface DocumentDetailed {
    id: string;
    number: string;
    documentType: DocumentType;
    consumer: Consumer;
    supplier: Supplier;
    documentStatusTransfer?: DocumentStatusTransfer;
    documentStatusTransferHistory?: DocumentStatusTransfer[];
    askedStartDate?: Date;
    askedFinishDate?: Date;
    approvedStartDate?: Date;
    approvedFinishDate?: Date;
    conclusion?: string;
    wishedItems?: WishedItem[];
    bookedItems?: BookedItem[];
}

export interface WishedItem {
    id: number;
    equipmentGroup: EquipmentGroup;
    amount: number;
    comment: string;
}

export interface BookedItem {
    id: number;
    equipment: Equipment;
    amount: number;
}

export interface DocTypeAndStatusMapping {
    statusName: string[]
    title: string[]
}

export const docTypeAndStatusMap = new Map<string, DocTypeAndStatusMapping>([
    ['Gift', {
        statusName: [
            'Created',
            'Completed'
        ],
        title: [
            'Equipment for donation',
            ''
        ]
    }],
]);

