import {DocumentDetailed,} from "../../../../models/documents/Documents";
import DocumentCardHistoryTabCompleted from "./DocumentCardHistoryTabCompleted";
import DocumentCardHistoryTabActiveDefault from "./DocumentCardHistoryTabActiveDefault";
import {
    isDocumentFullyCompleted,
    nextDocButtons,
    nextDocStatus,
    selectItemsList
} from "../../../../services/documents/DocumentUtils";
import {docTypeAndStatusMap, DocumentTypes} from "../../../../models/documents/DocumentViews";
import DocumentCardHistoryTitleAndItemsView from "./DocumentCardHistoryTitleAndItemsView";
import DocumentCardHistoryTabUnreached from "./DocumentCardHistoryTabUnreached";
import DocumentDonationCardActiveTabWithText from "../donation/DocumentDonationCardActiveTabWithText";
import DocumentLoanCardActiveTabWithText from "../loan/DocumentLoanCardActiveTabWithText";
import DocumentCardHistoryTabCompletedWithTextArea from "./DocumentCardHistoryTabCompletedWithTextArea";


function DocumentCardHistory(
    {doc,}: Readonly<{ doc: DocumentDetailed, }>
) {


    // completed steps
    const docTransferHistorySize = doc?.documentStatusTransferHistory?.length ?? 0;
    const docTypeName = doc?.documentType.name
    const typeAndStatusMap = docTypeAndStatusMap.get(docTypeName)!
    const completedTabs = doc?.documentStatusTransferHistory?.map((transferInfo, index) => {
        const title = typeAndStatusMap.title[index] ?? 'Operation is completed'
        const statusName = typeAndStatusMap.statusName[index] ?? 'Completed'
        if (statusName === 'Completed') {
            return <DocumentCardHistoryTabCompletedWithTextArea key={'doc_history_complete_tab_' + index}
                                                                statusTitle={statusName}
                                                                statusDate={transferInfo.operationDate}
                                                                initiatorFio={`${transferInfo.initiator.name} ${transferInfo.initiator.name}`}
                                                                title={title}
                                                                textAreaValue={doc?.conclusion}/>
        } else {
            return <DocumentCardHistoryTabCompleted key={'doc_history_complete_tab_' + index}
                                                    tabTitle={title}
                                                    tabStatusName={statusName}
                                                    items={selectItemsList(transferInfo.toStatus, doc)}
                                                    currentStatusTransfer={transferInfo}
                                                    isAccordionOpen={index === docTransferHistorySize - 1}/>
        }
    })

    // active step
    const isDocCompleted = isDocumentFullyCompleted(doc)
    const lastStepName = doc?.documentStatusTransfer?.toStatus!
    const activeDocStatus = nextDocStatus(lastStepName, docTypeName)

    const activeDocStep = typeAndStatusMap?.statusName.indexOf(activeDocStatus)
    const allDocStepsSize = typeAndStatusMap?.statusName.length
    const activeTabContent = () => <DocumentCardHistoryTitleAndItemsView
        tabTitle={typeAndStatusMap.title[activeDocStep]}
        items={selectItemsList(activeDocStatus, doc)}/>
    const activeTabButtons = nextDocButtons(lastStepName, docTypeName)

    const activeTab = () => {
        if (!isDocCompleted) {
            switch (docTypeName) {
                // donation has only 2 steps, so if the doc is not completed, here is the last step
                case DocumentTypes.DONATION: {
                    return <DocumentDonationCardActiveTabWithText doc={doc}/>
                }
                // loan has 2 types of steps - with only buttons, and the last one with text
                case DocumentTypes.LOAN: {
                    if (activeDocStep + 1 === allDocStepsSize) {
                        return <DocumentLoanCardActiveTabWithText doc={doc}/>
                    } else {
                        return <DocumentCardHistoryTabActiveDefault
                            statusTitle={activeDocStatus}
                            buttons={activeTabButtons}
                            tabContent={activeTabContent()}
                        />
                    }
                }
            }
        }
    }

    // plated/unreached yet steps
    const planedTabs = []
    if (!isDocCompleted) {
        for (let i = activeDocStep + 1; i < allDocStepsSize; i++) {
            planedTabs.push(
                <DocumentCardHistoryTabUnreached key={"unreached-tab_" + i}
                                                 tabStatusName={typeAndStatusMap?.statusName[i]}/>
            )
        }
    }

    return (
        <>
            {completedTabs}
            {activeTab()}
            {planedTabs}
        </>
    );
}

export default DocumentCardHistory;

