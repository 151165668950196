import Button from "../../../../components/Button";
import {DocHistTabButton} from "../../../../models/documents/DocumentViews";
import CustomAccordion from "../../../../components/CustomAccordion";
import DocumentCardHistoryTabHeader from "./DocumentCardHistoryTabHeader";

function DocumentCardHistoryTabActiveDefault(
    {
        statusTitle,
        tabContent,
        isAccordionOpen = true,
        buttons = []
    }: Readonly<{
        statusTitle: string,
        tabContent?: any,
        isAccordionOpen?: boolean,
        buttons: DocHistTabButton[]
    }>) {

    const buttonsView = buttons.map((button, index) =>
        <Button key={'doc_history_button_' + index}
                btnLabel={button.buttonName}
                btnClassName={button.buttonClasses}
                type="submit"
                children=""
                onClick={button.buttonAction}
        />
    )

    const accordionContent = () =>
        <>
            {tabContent}
            <div className={"document-card_data-step_content"}>
                <div className="document-card_data-step_buttons">
                    {buttonsView}
                </div>
            </div>
        </>


    return (
        <div className={"document-card_data-step"}>
            <CustomAccordion
                inOpenInitial={isAccordionOpen}
                accordionContent={accordionContent}
                buttonLineContent={<DocumentCardHistoryTabHeader tabStatusName={statusTitle}/>}
            />
        </div>
    );
}

export default DocumentCardHistoryTabActiveDefault;

