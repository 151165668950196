import {format} from "date-fns/format";
import {BookedItem, DocumentStatusTransfer} from "../../../../models/documents/Documents";
import CustomAccordion from "../../../../components/CustomAccordion";
import {CircleCheck} from "akar-icons";

function DocumentCardHistoryTabCompleted(
    {
        items,
        currentStatusTransfer,
        tabStatusName,
        tabTitle,
        isAccordionOpen
    }:
    Readonly<{
        items: BookedItem[],
        currentStatusTransfer: DocumentStatusTransfer,
        tabStatusName: string,
        tabTitle: string,
        isAccordionOpen: boolean
    }>
) {

    const donatedItems = items.map((item, index) =>
        <div key={'document-card_data-step_item' + index} className="document-card_data-step_item">
            <div>{item.equipment.name}</div>
            <div>{item.id}</div>
            <div>{item.amount}</div>
        </div>
    )

    const statusTransferInitiator = currentStatusTransfer.initiator

    const accordionContent = () =>
        <div className={"document-card_data-step_content"}>
            <div className={"document-card_data-step_line document-card_data-step_content-text"}>
                {tabTitle}
            </div>

            <div className={"document-card_data-step_line document-card_data-step_items"}>
                {donatedItems}
            </div>
        </div>


    return (
        <div className={"document-card_data-step"}>
            <CustomAccordion
                inOpenInitial={isAccordionOpen}
                accordionContent={accordionContent}
                buttonLineContent={
                    <div className={"document-card_data-step_header"}>
                        <div className={"document-card_data-step_status"}>
                            <div className={"document-card_data-step_status-name"}>
                                <CircleCheck strokeWidth={1} size={40}
                                             className={"document-card_data-step_status-name-icon"}/>
                                <div className={"document-card_data-step_status-name-text"}>{tabStatusName}</div>
                            </div>
                            <div
                                className={"document-card_data-step_status-date"}>{format(currentStatusTransfer.operationDate, "dd MMM yyyy 'at' hh:mm")}</div>
                        </div>

                        <div className={"document-card_data-step_initiator"}>
                            <div className={"document-card_data-step_initiator-title"}>Initiator</div>
                            <div
                                className={"document-card_data-step_initiator-value"}>{statusTransferInitiator.name} {statusTransferInitiator.surname}</div>
                        </div>
                    </div>
                }
            />
        </div>
    );
}

export default DocumentCardHistoryTabCompleted;

