import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../components/Button";
import ButtonText from "../components/ButtonText";
import { baseUrl } from "../config";
import InputMask from "react-input-mask";
import {USER_LINK_PATTERN} from "../consts";

function AddUser() {
    const {
        register,
        formState: { errors, isValid },
        handleSubmit,
        reset,
        trigger,
        watch,
    } = useForm({
        defaultValues: {
            role: "User",
            link: "",
        },
        mode: "onChange",
    });

    const [stepActive, setStepActive] = useState(1);
    const [passwordShown, setPasswordShown] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        trigger();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepActive]);

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    let onSubmit = async (data) => {
        data.status = "Active";

        try {
            let res = await fetch(baseUrl + "/user-service/add-user", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            // let resJson = await res.json();
            if (res.status === 200) {
                reset();
                navigate("/users");
            } else {
                alert("Some error occured");
            }
        } catch (err) {
            console.log(err);
        }
    };

    const onError = (error) => {
        console.log("error", error);
    };

    watch();

    return (
        <div className="add-user__wrapper">
            <Link to="/users">
                <div className="add-user__icon-back">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <path
                            d="M20 26L10 16L20 6"
                            stroke="#0A0A0A"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            </Link>

            <div className="add-user__content">
                <h3 className="add-user__title">Create new user</h3>

                <div className="add-user__steps">
                    <div className={stepActive === 1 ? "add-user__step step_active" : "add-user__step"}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="15" stroke="#694DF9" />
                            <path d="M14.0455 11.76V10.432H17.0535V22H15.5815V11.76H14.0455Z" />
                        </svg>

                        <p className="add-user__step-title">Step 1</p>
                    </div>
                    <div className="line">
                        <svg width="100" height="3" viewBox="0 0 100 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M 1 1.0001 L 100 1" stroke="#C2C2C2" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                    </div>
                    <div className={stepActive === 2 ? "add-user__step step_active" : "add-user__step"}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="15" />
                            <path d="M12.2144 20.832C13.5691 19.744 14.6304 18.8533 15.3984 18.16C16.1664 17.456 16.8118 16.7253 17.3344 15.968C17.8678 15.2 18.1344 14.448 18.1344 13.712C18.1344 13.0187 17.9638 12.4747 17.6224 12.08C17.2918 11.6747 16.7531 11.472 16.0064 11.472C15.2811 11.472 14.7158 11.7013 14.3104 12.16C13.9158 12.608 13.7024 13.2107 13.6704 13.968H12.2624C12.3051 12.7733 12.6678 11.8507 13.3504 11.2C14.0331 10.5493 14.9131 10.224 15.9904 10.224C17.0891 10.224 17.9584 10.528 18.5984 11.136C19.2491 11.744 19.5744 12.5813 19.5744 13.648C19.5744 14.5333 19.3078 15.3973 18.7744 16.24C18.2518 17.072 17.6544 17.808 16.9824 18.448C16.3104 19.0773 15.4518 19.8133 14.4064 20.656H19.9104V21.872H12.2144V20.832Z" />
                        </svg>
                        <p className="add-user__step-title">Step 2</p>
                    </div>
                </div>
                <div className="add-user__info">
                    <form className="add-user__form form" onSubmit={handleSubmit(onSubmit, onError)}>
                        <div className={stepActive === 1 ? "add-user__form-step form_active" : "add-user__form-step"}>
                            <div className="form__block">
                                <label htmlFor="username" className="label">
                                    Name
                                </label>
                                <input
                                    {...register("name", {
                                        required: "This is required",
                                        pattern: {
                                            value: /^[A-zА-яЁё]{2,15}$/,
                                            message:
                                                "User name should have from 2 to 15 characters and contains only " +
                                                "latin and/or cyrillic characters",
                                        },
                                    })}
                                    type="text"
                                    name="name"
                                    id="name"
                                    className="input"
                                    placeholder="Name"
                                />

                                {errors?.name && <p className="error">{errors?.name?.message || "Error!"}</p>}
                            </div>
                            <div className="form__block">
                                <label htmlFor="surname" className="label">
                                    Surname
                                </label>
                                <input
                                    {...register("surname", {
                                        required: "This is required",
                                        pattern: {
                                            value: /^[A-zА-яЁё-]{1,25}$/,
                                            message:
                                                "User surname should have from 1 to 25 characters and contains only " +
                                                "latin, cyrillic and/or [-] characters",
                                        },
                                    })}
                                    type="text"
                                    name="surname"
                                    id="surname"
                                    className="input"
                                    placeholder="Surname"
                                />

                                {errors?.surname && <p className="error">{errors?.surname?.message || "Error!"}</p>}
                            </div>
                            <div className="form__block">
                                <label htmlFor="password" className="label">
                                    Password
                                </label>
                                <div className="password__container">
                                    <input
                                        {...register("password", {
                                            required: "This is required",
                                            pattern: {
                                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,15}$/,
                                                message:
                                                    "User password should have from 6 to 15 characters, at least " +
                                                    "one uppercase letter, one lowercase letter, one number and one " +
                                                    "special character [@$!%*?&]",
                                            },
                                        })}
                                        name="password"
                                        type={passwordShown ? "text" : "password"}
                                        id="password"
                                        placeholder="Password"
                                        className="input"
                                        autoComplete="on"
                                    />
                                    <div
                                        className={passwordShown ? "btn-password btn-password_active" : "btn-password"}
                                        onClick={togglePasswordVisiblity}
                                    >
                                        <img src="/Eye.svg" alt="password" />
                                    </div>
                                </div>

                                {errors?.password && <p className="error">{errors?.password?.message || "Error!"}</p>}
                            </div>
                            <div className="form__block">
                                <p className="label">Role</p>
                                <div className="radio">
                                    <div className="radio_item">
                                        <label htmlFor="admin" className="radio__label">
                                            <input
                                                {...register("role")}
                                                name="role"
                                                type="radio"
                                                id="admin"
                                                className="radio__input"
                                                value="Admin"
                                            />{" "}
                                            <span>Admin</span>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="radio_item">
                                        <label htmlFor="user" className="radio__label">
                                            <input
                                                {...register("role")}
                                                name="role"
                                                type="radio"
                                                id="user"
                                                className="radio__input"
                                                value="User"
                                            />
                                            <span>User</span>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-container btn-container-center">
                                <Button
                                    type="button"
                                    btnLabel="Next"
                                    btnClassName="btn_default btn-medium"
                                    onClick={() => setStepActive(2)}
                                    disabled={!(!errors?.name && !errors?.surname && !errors?.password)}
                                />
                            </div>
                        </div>
                        <div className={stepActive === 2 ? "add-user__form-step form_active" : "add-user__form-step"}>
                            <div className="form__block">
                                <label htmlFor="link" className="label">
                                    Link
                                </label>
                                <input
                                    {...register("link", {
                                        required: "This is required",
                                        maxLength: {
                                            value: 255,
                                            message: "user link should have up to 255 characters",
                                        },
                                        pattern: {
                                            value: USER_LINK_PATTERN,
                                            message: "User link should be a link to facebook, vk, ok, or telegram",
                                        },
                                    })}
                                    name="link"
                                    type="text"
                                    id="link"
                                    className="input"
                                    placeholder="Link"
                                />

                                {errors?.link && <p className="error">{errors?.link?.message || "Error!"}</p>}
                            </div>
                            <div className="form__block">
                                <label htmlFor="phone" className="label">
                                    Phone
                                </label>

                                <InputMask
                                    {...register("phone", {
                                        required: "This is required",
                                        pattern: {
                                            value: /^\+7-9\d{2}-\d{3}-\d{2}-\d{2}$/,
                                            message: "User phone should match pattern '+7-9##-###-##-##'",
                                        },
                                    })}
                                    mask="+7-999-999-99-99"
                                    className="input"
                                    name="phone"
                                    id="phone"
                                ></InputMask>

                                {errors?.phone && <p className="error">{errors?.phone?.message || "Error!"}</p>}
                            </div>
                            <div className="form__block">
                                <label htmlFor="department" className="label">
                                    Department
                                </label>
                                <input
                                    {...register("department", {
                                        required: "This is required",
                                        maxLength: {
                                            value: 100,
                                            message: "User department should have up to 100 characters",
                                        },
                                    })}
                                    name="department"
                                    type="text"
                                    id="department"
                                    className="input"
                                    placeholder="Department"
                                />

                                {errors?.department && (
                                    <p className="error">{errors?.department?.message || "Error!"}</p>
                                )}
                            </div>
                            <div className="form__block">
                                <label htmlFor="faculty" className="label">
                                    Faculty <span className="font-size-small">-</span>{" "}
                                    <span className="style-italic">Optional</span>
                                </label>
                                <input
                                    {...register("faculty", {
                                        required: false,
                                        maxLength: {
                                            value: 100,
                                            message: "User faculty should have up to 100 characters",
                                        },
                                    })}
                                    name="faculty"
                                    type="text"
                                    id="faculty"
                                    className="input"
                                    placeholder="Faculty"
                                />

                                {errors?.faculty && <p className="error">{errors?.faculty?.message || "Error!"}</p>}
                            </div>
                            <div className="form__block">
                                <label htmlFor="group" className="label">
                                    Group <span className="font-size-small">-</span>{" "}
                                    <span className="style-italic">Optional</span>
                                </label>
                                <input
                                    {...register("group", {
                                        required: false,
                                        maxLength: {
                                            value: 20,
                                            message: "User group should have up to 20 characters",
                                        },
                                    })}
                                    name="group"
                                    type="text"
                                    id="group"
                                    className="input"
                                    placeholder="Group"
                                />
                                {errors?.group && <p className="error">{errors?.group?.message || "Error!"}</p>}
                            </div>
                            <div className="btn-container">
                                <ButtonText btnLabel="Previous" onClick={() => setStepActive(1)} />

                                <Button
                                    btnLabel="Done"
                                    btnClassName="btn_default btn-medium"
                                    type="submit"
                                    disabled={!isValid}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
export default AddUser;

