import InfoTable from "../../../../components/InfoTable";
import {DocumentDetailed} from "../../../../models/documents/Documents";
import {format} from "date-fns/format";

function DocumentLoanCardDetails({doc}: Readonly<{ doc: DocumentDetailed }>) {

    const infoData = [
        [
            {title: "Consumer", value: `${doc.consumer.name} ${doc.consumer.surname}`},
            {title: "Supplier", value: "Stock"},
        ],
        [
            {title: "Expedition", value: 'Not implemented yet'},
        ],
        [
            {title: "Start date", value: format(doc.askedStartDate!, 'dd.MM.yyyy')},
            {title: "End date", value: format(doc.askedFinishDate!, 'dd.MM.yyyy')},
        ],
    ]

    const approvedDates = []
    if (doc.approvedStartDate) {
        approvedDates.push({title: "Equipment issue date", value: format(doc.approvedStartDate, 'dd.MM.yyyy')})
    }
    if (doc.approvedFinishDate) {
        approvedDates.push({title: "Equipment return date", value: format(doc.approvedFinishDate, 'dd.MM.yyyy')})
    }
    if (approvedDates.length) {
        infoData.push(approvedDates)
    }

    return (
        <InfoTable title={"Customer"}
                   subtitle={"Information Details"}
                   infoData={infoData}></InfoTable>
    );
}

export default DocumentLoanCardDetails;

