import DocumentCardHistoryTabHeader from "./DocumentCardHistoryTabHeader";

function DocumentCardHistoryTabUnreached(
    {
        tabStatusName,
    }: Readonly<{
        tabStatusName: string,
    }>
) {


    return (
        <div className={"document-card_data-step unreached"}>
            <DocumentCardHistoryTabHeader
                tabStatusName={tabStatusName}
                isActive={false}/>
        </div>
    );
}

export default DocumentCardHistoryTabUnreached;

