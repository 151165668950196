import DocumentCardHistoryTabActiveWithTextArea from "../history/DocumentCardHistoryTabActiveWithTextArea";
import {DocumentDetailed} from "../../../../models/documents/Documents";

function DocumentLoanCardActiveTabWithText(
    {doc}: Readonly<{ doc: DocumentDetailed }>
) {

    let enteredText = ''
    const handleFormChange = (textAreaData: string) => {
        enteredText = textAreaData
    }

    let onSubmit = () => {
        console.log(doc)
        console.log(enteredText)
    };

    const buttons = [
        {
            buttonName: 'Confirm return',
            buttonAction: onSubmit,
            buttonClasses: 'btn_default btn-medium'
        },
    ]

    return (
        <DocumentCardHistoryTabActiveWithTextArea
            statusTitle="Completed"
            title="Order report"
            buttons={buttons}
            placeholderTest="Please provide feedback"
            callBackForm={handleFormChange}/>
    );
}

export default DocumentLoanCardActiveTabWithText;

