/**
 * Statuses do not much on UI -> backend
 */
export enum DocumentStatus {
    CREATED = 'Created',
    BOOKED = 'Booked',
    ISSUED = 'Granted',
    COMPLETED = 'Completed',
    REJECTED = 'Rejected',
    UNKNOWN = 'Unknown',
}

/**
 * as well as doc types
 */
export enum DocumentTypes {
    DONATION = 'Gift',
    LOAN = 'Loan'
}

export interface DocTypeAndStatusMapping {
    statusName: string[]
    title: string[]
}

export const docTypeAndStatusMap = new Map<string, DocTypeAndStatusMapping>([
    [DocumentTypes.DONATION, {
        statusName: [
            DocumentStatus.CREATED,
            DocumentStatus.COMPLETED
        ],
        title: [
            'Equipment for donation',
            'Reason of Donation'
        ]
    }],
    [DocumentTypes.LOAN, {
        statusName: [
            DocumentStatus.CREATED,
            DocumentStatus.BOOKED,
            'Issued', // use one more enum?
            DocumentStatus.COMPLETED,
        ],
        title: [
            'Requested equipment',
            'Booked equipment',
            'Equipment for rent',
            'Order report',
        ]
    }]
]);

export interface DocHistTabButton {
    buttonName: string,
    buttonClasses?: string
    buttonAction: (data?: any) => void,
}

export interface AcceptDonationRequest {
    initiator: {
        id: string
    },
    number: string,
    conclusion: string
}