import React from "react";
import Calendar from "../../components/Calendar";
import Dropdown from "../../components/Dropdown";
import Search from "../../components/Search";

import CustomSelect from "../../components/CustomSelect";
import DocumentsTable from "../../components/DocumentsTable";

interface Option {
    value: string;
    label: string;
}

function Documents() {
    const [searchValue, setSearchValue] = React.useState("");
    const [selectedStatus, setSelectedStatus] = React.useState<Option[]>([]);
    const [selectedType, setSelectedType] = React.useState<Option[]>([]);
    const [dateRange, setDateRange] = React.useState<[Date | undefined, Date | undefined]>([undefined, undefined]);

    const statusOptions: Option[] = [
        { value: "created", label: "Created" },
        { value: "booked", label: "Booked" },
        { value: "issued", label: "Issued" },
        { value: "completed", label: "Completed" },
        { value: "rejected", label: "Rejected" },
    ];

    const typeOptions: Option[] = [
        { value: "loan", label: "Loan" },
        { value: "purchase", label: "Purchase" },
        { value: "loss", label: "Loss" },
    ];

    const dropdownOptions = [
        { value: "option1", label: "Loan", disabled: false },
        { value: "option2", label: "Donation", disabled: true },
        { value: "option2", label: "Loss", disabled: true },
    ];

    return (
        <>
            <div className="search-and-actions">
                <div className="filter-panel">
                    <Search
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        placeholder={"Search by document number or consumer..."}
                        className="documents-table_search"
                    />
                    <Calendar className="documents-table_calendar" setDateRange={setDateRange} />
                    <CustomSelect
                        className="documents-table_select"
                        placeholder="Status"
                        options={statusOptions}
                        value={selectedStatus}
                        onChange={(newValue) => setSelectedStatus(newValue as Option[])}
                    />
                    <CustomSelect
                        className="documents-table_calendar"
                        placeholder="Type"
                        options={typeOptions}
                        value={selectedType}
                        onChange={(newValue) => setSelectedType(newValue as Option[])}
                    />
                </div>

                <div className="equipment__btn-wrapper">
                    <Dropdown options={dropdownOptions} />
                </div>
            </div>

            <DocumentsTable
                searchValue={searchValue}
                selectedStatus={selectedStatus}
                selectedType={selectedType}
                dateRange={dateRange}
            />
        </>
    );
}
export default Documents;

