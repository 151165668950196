import {DocumentStatusTransfer} from "../../../../models/documents/Documents";
import CustomAccordion from "../../../../components/CustomAccordion";
import DocumentCardHistoryTitleAndItemsView from "./DocumentCardHistoryTitleAndItemsView";
import DocumentCardHistoryTabHeader from "./DocumentCardHistoryTabHeader";

function DocumentCardHistoryTabCompleted(
    {
        items,
        currentStatusTransfer,
        tabStatusName,
        tabTitle,
        isAccordionOpen
    }:
    Readonly<{
        items: any[],
        currentStatusTransfer: DocumentStatusTransfer,
        tabStatusName: string,
        tabTitle: string,
        isAccordionOpen: boolean
    }>
) {

    const statusTransferInitiator = currentStatusTransfer.initiator
    const accordionContent = () => <DocumentCardHistoryTitleAndItemsView tabTitle={tabTitle} items={items}/>

    return (
        <div className={"document-card_data-step"}>
            <CustomAccordion
                inOpenInitial={isAccordionOpen}
                accordionContent={accordionContent}
                buttonLineContent={<DocumentCardHistoryTabHeader
                    tabStatusName={tabStatusName}
                    statusDate={currentStatusTransfer.operationDate}
                    initiatorFio={`${statusTransferInitiator.name} ${statusTransferInitiator.surname}`}/>}
            />
        </div>
    );
}

export default DocumentCardHistoryTabCompleted;

