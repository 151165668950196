export interface InfoTableData {
    title: string,
    value: string
}

function InfoTable({title, subtitle, infoData}: Readonly<{
    title?: string,
    subtitle?: string,
    infoData: InfoTableData[][]
}>) {

    const infoTableItemRows = infoData.map((dataRow, index) => {
            if (dataRow.length > 0) {
                const infoTableItems = dataRow.map((data, index) =>
                    <div key={'info-table-item' + index} className="info-table-item">
                        <div className={"info-table-item-title"}>{data.title}</div>
                        <div className={"info-table-item-value"}>{data.value}</div>
                    </div>)
                return <div key={'info-table-item-row' + index}
                            className="info-table-item-row">
                    {infoTableItems}
                </div>
            }
            return <></>
        }
    )

    return (
        <div className={"info-table"}>
            {title ? (<div className={"info-table_title"}>{title}</div>) : (<></>)}
            {subtitle ? (<div className={"info-table_subtitle"}>{subtitle}</div>) : (<></>)}
            <div className={"info-table_data-separator"}></div>
            {infoTableItemRows}
        </div>
    );
}

export default InfoTable;
