import {useForm} from "react-hook-form";
import Button from "../../../../components/Button";
import {baseUrl} from "../../../../config";
import {DocumentDetailed} from "../../../../models/documents/Documents";
import {useNavigate} from "react-router-dom";
import {Circle} from "akar-icons";

function DocumentCardHistoryTabActive({doc}: Readonly<{ doc: DocumentDetailed }>) {

    const {register, handleSubmit,} = useForm({})
    const navigate = useNavigate()

    let onSubmit = async (data: any) => {
        const requestBody = {
            initiator: {
                id: doc.documentStatusTransfer?.initiator?.id!
            },
            number: doc.number,
            conclusion: data.reason
        }

        try {
            const res = await fetch(baseUrl + "/donation-document-service/accept-donation", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody)
            });

            if (res.ok) {
                navigate(`/documents/${doc.number}`);
            } else {
                alert('Error occurred');
            }

        } catch (err) {
            console.error(err);
        }
    };

    const editSelection = () => {
        // todo not implemented yet
    }

// todo this is only for donation now
    return (
        <div className={"document-card_data-step"}>
            <div className={"document-card_data-step_line document-card_data-step_header"}>
                <div className={"document-card_data-step_status"}>
                    <div className={"document-card_data-step_status-name"}>
                        <Circle strokeWidth={1} size={36} className={"document-card_data-step_status-name-icon"}/>
                        <div className={"document-card_data-step_status-name-text"}>Completed</div>
                    </div>
                </div>
            </div>

            <form className="document-card_data-step_reason_input form" onSubmit={handleSubmit(onSubmit)}>
                <div className={"document-card_data-step_reason_input_label text-16-bold"}>Reason of Donation</div>
                <div className="form__block">
                        <textarea
                            {...register("reason")}
                            className="textarea"
                            placeholder="Enter the reason for donating the item (e.g. no longer needed, suplus stock)"
                        />
                </div>

                <div className="document-card_data-step_buttons">
                    <Button btnLabel="Confirm Donation"
                            btnClassName="btn_default btn-medium"
                            type="submit"
                            children=""
                    />
                    <Button btnLabel="Edit Selection"
                            btnClassName="btn_outline btn-medium"
                            onClick={editSelection}
                            children=""/>
                </div>
            </form>


        </div>
    );
}

export default DocumentCardHistoryTabActive;

