import {AcceptDonationRequest} from "../../models/documents/DocumentViews";
import {baseUrl} from "../../config";

export const acceptDonationDocument = async (
    request: AcceptDonationRequest,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void,
) => {
    try {
        const res = await fetch(baseUrl + "/donation-document-service/accept-donation", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request)
        });

        if (res.ok && onSuccessCallback) {
            onSuccessCallback()
        } else {
            if (onErrorCallback) {
                onErrorCallback()
            }
        }

    } catch (err) {
        console.error(err);
    }
};