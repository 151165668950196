import {ReactElement, useState} from "react";
import {ChevronDown, ChevronUp} from "akar-icons";

function CustomAccordion(
    {
        accordionContent,
        inOpenInitial = true,
        showLessText = 'Show less',
        showMoreText = 'Show more',
        buttonLineContent
    }: Readonly<{
        accordionContent: any,
        inOpenInitial?: boolean,
        showLessText?: string,
        showMoreText?: string,
        buttonLineContent?: ReactElement
    }>) {

    const [accordionState, setAccordionState] = useState(inOpenInitial ?? true);

    const toggleAccordion = () => {
        setAccordionState(!accordionState);
    }

    return (
        <>
            <div className={"accordion-button-line-content"}>
                {buttonLineContent}
                <div className={"accordion-button-line_very-button_block"}
                     onClick={() => toggleAccordion()}>
                    {accordionState ?
                        (<>
                            <div className={"accordion-toggle-text"}>{showLessText}</div>
                            <ChevronUp strokeWidth={1} size={16} className={"accordion-chevron"}/>
                        </>)
                        :
                        (<>
                            <div className={"accordion-toggle-text"}>{showMoreText}</div>
                            <ChevronDown strokeWidth={1} size={16} className={"accordion-chevron"}/>
                        </>)}
                </div>
            </div>
            {accordionState && accordionContent()}
        </>
    );
}

export default CustomAccordion;
