import {useEffect, useState} from "react";
import {baseUrl} from "../../../config";
import {useNavigate, useParams} from "react-router-dom";
import {DocumentDetailed} from "../../../models/documents/Documents";
import DocumentDonationCardDetails from "./donation/DocumentDonationCardDetails";
import GoBackButton from "../../../components/GoBackButton";
import DocumentCardHistory from "./history/DocumentCardHistory";


function DocumentCard() {
    const {docNumber} = useParams();
    const navigate = useNavigate();
    const [item, setItem] = useState<DocumentDetailed>();

    useEffect(() => {
        fetch(baseUrl + `/document-service/get-document?number=${docNumber}`)
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Network response was not ok");
                }
                return res.json();
            })
            .then((response) => {
                setItem(response.data);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [docNumber]);

    const displayDocTypeName = () => {
        switch (item?.documentType.name) {
            case 'Gift':
                return 'Donation'
            default:
                return 'Document'
        }
    };

    const goToDocumentList = () => navigate(`/documents`);

    return (
        <>
            <GoBackButton
                btnLabel={`${displayDocTypeName()} ${item?.number}`}
                onClick={goToDocumentList}/>
            <div className="document-card">
                <div className="document-card_data">
                    <DocumentCardHistory document={item!}/>
                </div>
                <div className="document-card_details">
                    {(() => {
                        switch (item?.documentType?.name) {
                            case 'Gift': {
                                return <DocumentDonationCardDetails></DocumentDonationCardDetails>
                            }
                            default: {
                                return <div>Unknown document type</div>
                            }
                        }
                    })()}
                </div>
            </div>

        </>
    );
}

export default DocumentCard;

