import {useForm} from "react-hook-form";
import DocumentCardHistoryTabHeader from "./DocumentCardHistoryTabHeader";

function DocumentCardHistoryTabCompletedWithTextArea(
    {
        statusTitle,
        statusDate,
        initiatorFio,
        title,
        textAreaValue,
    }: Readonly<{
        statusTitle: string,
        statusDate: Date,
        initiatorFio: string,
        title: string,
        textAreaValue?: string,
    }>) {

    const {register} = useForm({
        defaultValues: {
            reason: textAreaValue
        }
    })

    return (
        <div className={"document-card_data-step"}>
            <DocumentCardHistoryTabHeader
                tabStatusName={statusTitle}
                statusDate={statusDate}
                initiatorFio={initiatorFio}/>

            <div className="document-card_data-step_reason_input">
                <div className={"document-card_data-step_reason_input_label text-16-bold"}>{title}</div>
                <div className="form__block">
                        <textarea
                            {...register("reason")}
                            className="textarea"
                            disabled={true}
                            value={textAreaValue}
                        />
                </div>
            </div>
        </div>
    );
}

export default DocumentCardHistoryTabCompletedWithTextArea;

