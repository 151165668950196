import InfoTable from "../../../../components/InfoTable";

function DocumentDonationCardDetails() {

    return (
        <InfoTable title={"Operation Details"}
                   infoData={[
                       [
                           {title: "Consumer", value: "External world"},
                           {title: "Supplier", value: "Stock"},
                       ],
                   ]}></InfoTable>
    );
}

export default DocumentDonationCardDetails;

